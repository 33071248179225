import React, { useEffect, useState } from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CasinoProviders } from "../lib/data";

import ProviderLogo1 from "../assets/images/providers/ProviderImg1.jpg";
import ProviderLogo2 from "../assets/images/providers/ProviderImg2.jpg";
import ProviderLogo3 from "../assets/images/providers/ProviderImg3.jpg";
import ProviderLogo4 from "../assets/images/providers/spribe.png";
import ProviderLogo5 from "../assets/images/providers/netent.png";
import ProviderLogo6 from "../assets/images/providers/redtiger.png";
import ProviderLogo7 from "../assets/images/providers/ProviderImg7.jpg";
import { Col, Container, Row } from "react-bootstrap";

import PaymentLogo1 from "../assets/images/payments/visa.png";
import PaymentLogo2 from "../assets/images/payments/mastercard.png";
import PaymentLogo3 from "../assets/images/payments/Skrill.png";
import PaymentLogo4 from "../assets/images/payments/paytm.png";
import PaymentLogo5 from "../assets/images/payments/googlepay.png";
import PaymentLogo6 from "../assets/images/payments/UPI.png";
import PaymentLogo7 from "../assets/images/payments/phonepe.png";

import LiveMatch from "../assets/images/foot/live.png";
import SlotsFoot from "../assets/images/foot/slot-machine.png";
import MyBets from "../assets/images/foot/betting.png";

const Footer = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");

  const footerPages = [
    "home",
    "sportsbook",
    "casino/evolution",
    "casino/ezugi",
    "deposit",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <>
      <footer className="footer">
        <Container className="max-1200w d-none">
          <div className="providers_sec ">
            <Row>
              <Col md={6} xs={12}>
                <div className="provider-mob-border">
                  <div className="heading mb-4">
                    <div className="text">
                      <h5>Top Providers</h5>
                      <p>
                        Play Your favorite Casino Games with{" "}
                        <span>Top Providers</span>
                      </p>
                    </div>
                  </div>

                  <div className="providers_logo d-flex flex-wrap justify-content-center">
                    <a href="javascript:void(0)" className="logo_box">
                      <img src={ProviderLogo2} alt="Provider Logo" />
                    </a>
                    <a href="javascript:void(0)" className="logo_box">
                      <img src={ProviderLogo3} alt="Provider Logo" />
                    </a>
                    <a href="javascript:void(0)" className="logo_box">
                      <img src={ProviderLogo4} alt="Provider Logo" />
                    </a>
                    <a href="javascript:void(0)" className="logo_box">
                      <img src={ProviderLogo5} alt="Provider Logo" />
                    </a>
                    <a href="javascript:void(0)" className="logo_box">
                      <img src={ProviderLogo6} alt="Provider Logo" />
                    </a>
                    <a href="javascript:void(0)" className="logo_box">
                      <img src={ProviderLogo7} alt="Provider Logo" />
                    </a>
                  </div>
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="">
                  <div className="heading payment-heading mb-4 ">
                    <div className="text">
                      <h5>Payment Gateway</h5>
                      <p>
                        Accept Process &amp; disburse{" "}
                        <span>Digital Payments </span>
                        for your bussiness
                      </p>
                    </div>
                  </div>

                  <div className="providers_logo payment_logo d-flex flex-wrap justify-content-center">
                    <a href="javascript:void(0)" className="logo_box">
                      <img src={PaymentLogo1} alt="Provider Logo" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="logo_box payment-height"
                    >
                      <img src={PaymentLogo2} alt="Provider Logo" />
                    </a>
                    <a href="javascript:void(0)" className="logo_box">
                      <img src={PaymentLogo3} alt="Provider Logo" />
                    </a>
                    <a href="javascript:void(0)" className="logo_box">
                      <img src={PaymentLogo4} alt="Provider Logo" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="logo_box payment-height"
                    >
                      <img src={PaymentLogo5} alt="Provider Logo" />
                    </a>
                    <a href="javascript:void(0)" className="logo_box">
                      <img src={PaymentLogo6} alt="Provider Logo" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="logo_box payment-height"
                    >
                      <img src={PaymentLogo7} alt="Provider Logo" />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="providers_img ">
            <a href="javascript:void(0)">
              <img src={RNG} loading="lazy" alt="RNG Certified" />
            </a>
            <a href="javascript:void(0)">
              <img src={Gambling} loading="lazy" alt="Gambling Commission" />
            </a>
            <a href="javascript:void(0)">
              <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
            </a>
            <a href="javascript:void(0)">
              <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
            </a>
          </div>

          <div className="footer-links ">
            <ul>
              <li>
                <a href="/contactus">Contact Us</a>
              </li>
              <li>
                <a href="/privacypolicy ">Privacy Policy</a>
              </li>
              <li>
                <a href="/responsiblegame">Responsible Gaming</a>
              </li>
              <li>
                <a href="/fairplay">Fair Play</a>
              </li>
              <li>
                <a href="/gamesrule">Games Rules</a>
              </li>
              <li>
                <a href="/terms">Terms and Conditions</a>
              </li>
            </ul>
          </div>

          <div className="helpline d-none">
            <a
              className="panel"
              href={
                appDetails?.WHATS_APP_URL != ""
                  ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                  : "javascript:void(0)"
              }
              target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
            >
              WhatsApp Us{" "}
            </a>
          </div>

          {/* <div class="d-flex justify-content-center mt-2">
          <img
            src={PaymentMod}
            loading="lazy"
            style={{ width: "250px" }}
            alt="Payment Otions"
          />
        </div> */}

          <div class="d-flex justify-content-center align-items-center mt-3 mb-3 ">
            <img
              src={Betfair}
              loading="lazy"
              class="mb-3 mx-3 betfair"
              alt="Betfair Logo"
            />
            <a href="javascript:void(0)">
              <img
                src={Gamble18}
                loading="lazy"
                alt="Gamble Responsible"
                class="mb-3 betfair"
              />
            </a>
          </div>

          <p>{appDetails?.FOOTER_TEXT || ""}</p>
          <div className="paymentImages d-none">
            <ul>
              <li>
                <img src="./images/sewa.jpeg" />
              </li>
              <li>
                <img src="./images/khalti.jpeg" />
              </li>
            </ul>
          </div>

          <div className="social-icons d-none">
            <ul>
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? appDetails?.WHATS_APP_URL
                      : "javascript:void(0)"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  {/* <span className="notification-count">2</span> */}
                  <img src={Whatsapp} alt="Whatsapp Icon" />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails.TELEGRAM != ""
                      ? appDetails.TELEGRAM
                      : "javascript:void(0)"
                  }
                  target={appDetails.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={Telegram} alt="Telegram Icon" />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails.FACEBOOK != ""
                      ? appDetails.FACEBOOK
                      : "javascript:void(0)"
                  }
                  target={appDetails.FACEBOOK != "" ? "_blank" : ""}
                >
                  <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails.INSTATGRAM != ""
                      ? appDetails.INSTATGRAM
                      : "javascript:void(0)"
                  }
                  target={appDetails.INSTATGRAM != "" ? "_blank" : ""}
                >
                  <img src={Instagram} alt="Instagram Icon" />
                </a>
              </li>
            </ul>
          </div>
        </Container>

        <Container className="max-1200w">
          <Row>
            <Col md={3} xs={6} className="firstfooterlinks">
              <div className="footerlinks">
                <div className="footer-links">
                  <h2>Casino</h2>
                  <ul>
                    <li>
                      <a href="/casinogame">Casino</a>
                    </li>
                    <li>
                      <a href="/casinogame">TV Games</a>
                    </li>
                    <li>
                      <a href="/casinogame">Live Casino</a>
                    </li>
                    <li>
                      <a href="/casinogame">Casino Games</a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="footerlinks">
                <div className="footer-links">
                  <h2>SiteMap</h2>
                  <ul>
                    <li>
                      <a href="/home">Home</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">About Us</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)"> License</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)"> Bonus Policy</a>
                    </li>
                    <li>
                      <a href="/deposit">Deposit</a>
                    </li>
                    <li>
                      <a href="/withdraw">Withdraw Funds</a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="footerlinks">
                <div className="footer-links">
                  <h2>Policies</h2>
                  <ul>
                    <li>
                      <a href="/privacypolicy ">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/responsiblegame">Responsible Gaming</a>
                    </li>
                    <li>
                      <a href="/fairplay">Fair Play</a>
                    </li>
                    <li>
                      <a href="/gamesrule">Games Rules</a>
                    </li>
                    <li>
                      <a href="/terms">Terms and Conditions</a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="footerlinks">
                <div className="footer-links">
                  <h2>Support</h2>
                  <ul>
                    <li>
                      <a href="/contactus">Contact Us</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">FAQ</a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>

          <div className="cryptoCurrencyWrapper">
            <a href="javascript:void(0)" className="logo_box">
              <img src={PaymentLogo1} alt="Provider Logo" />
            </a>
            <a href="javascript:void(0)" className="logo_box payment-height">
              <img src={PaymentLogo2} alt="Provider Logo" />
            </a>
            <a href="javascript:void(0)" className="logo_box">
              <img src={PaymentLogo3} alt="Provider Logo" />
            </a>
            <a href="javascript:void(0)" className="logo_box">
              <img src={PaymentLogo4} alt="Provider Logo" />
            </a>
            <a href="javascript:void(0)" className="logo_box payment-height">
              <img src={PaymentLogo5} alt="Provider Logo" />
            </a>
            <a href="javascript:void(0)" className="logo_box">
              <img src={PaymentLogo6} alt="Provider Logo" />
            </a>
            <a href="javascript:void(0)" className="logo_box payment-height">
              <img src={PaymentLogo7} alt="Provider Logo" />
            </a>
          </div>

          <div className="footer-logo">
            <div className="logo">
              <a href="/">
                <img src={appDetails?.LOGO_URL || logo} alt="Logo" />
              </a>
            </div>
            <div className="">
              <div className="providers_img">
                <a href="javascript:void(0)">
                  <img src={RNG} loading="lazy" alt="RNG Certified" />
                </a>
                <a href="javascript:void(0)">
                  <img
                    src={Gambling}
                    loading="lazy"
                    alt="Gambling Commission"
                  />
                </a>
                <a href="javascript:void(0)">
                  <img
                    src={ResponsibleG}
                    loading="lazy"
                    alt="Responsible Gaming"
                  />
                </a>
                <a href="javascript:void(0)">
                  <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
                </a>
              </div>

              <div className="social-icons">
                <ul>
                  <li>
                    <a
                      className="panel"
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? appDetails?.WHATS_APP_URL
                          : "javascript:void(0)"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      {" "}
                      <img src={Whatsapp} alt="Whatsapp Icon" />
                      {/* <span className="notification-count">2</span> */}
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        appDetails?.TELEGRAM != ""
                          ? appDetails?.TELEGRAM
                          : "javascript:void(0)"
                      }
                      target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                    >
                      <img src={Telegram} alt="Telegram Icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        appDetails?.FACEBOOK != ""
                          ? appDetails?.FACEBOOK
                          : "javascript:void(0)"
                      }
                      target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                    >
                      <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        appDetails?.INSTATGRAM != ""
                          ? appDetails?.INSTATGRAM
                          : "javascript:void(0)"
                      }
                      target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                    >
                      <img src={Instagram} alt="Instagram Icon" />
                    </a>
                  </li>
                </ul>
              </div>

              <p>{appDetails?.FOOTER_TEXT || ""}</p>
            </div>
          </div>

          <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-lg-none">
            <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
              <li>
                <a
                  className={activePage === "home" ? "active" : ""}
                  href="/home"
                >
                  <HomeIcon />
                  <span>Home</span>
                </a>
              </li>
              <li>
                <a
                  className={activePage === "betby" ? "active" : ""}
                  href="/betby"
                >
                  <SportsIcon />
                  <span>Exchange</span>
                </a>
              </li>
              <li>
                <a href="/casinogame">
                  <GamesIcon />
                  <span>Casino</span>
                </a>
              </li>
              <li>
                <a
                  className={activePage === "casino/ezugi" ? "active" : ""}
                  href={CasinoProviders["ezugi"]?.href}
                >
                  <GamesIcon />
                  <span>Ezugi</span>
                </a>
              </li>
              <li>
                <a
                  className={activePage === "deposit" ? "active" : ""}
                  href="/deposit"
                >
                  <CashierIcon />
                  <span>Deposit</span>
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
